import Vue from "vue";
import { bolidStatuses } from "@/bolidStatuses";

export default Vue.extend({
  name: "Mixins",
  data() {
    return {
      inputTimer: {} as any,
      bolidStatuses,
    };
  },
  computed: {
    activationStatusOptions() {
      return Object.entries(bolidStatuses).map(([id, rest]) => {
        return { value: id, ...rest };
      });
    },
  },
  methods: {
    errorToast(error: any) {
      console.log(error);
      this.$toast.add({
        severity: "error",
        summary: this.$t("error"),
        detail: error.message,
        life: 5000,
      });
    },
    requestDelay(condition: boolean, callback: Function) {
      if (condition) {
        clearTimeout(this.$data.inputTimer);
        this.$data.inputTimer = setTimeout(callback, 1000);
      } else {
        clearTimeout(this.$data.inputTimer);
      }
    },
  },
});
