export const bolidStatuses = {
  0: { title: "0 - Неверный статус", icon: "mdi-fire", message: "" },
  1: { title: "1 - Восстановление сети 220 В", icon: "mdi-fire", message: "" },
  2: { title: "2 - Авария сети 220 В", icon: "mdi-fire", message: "" },
  3: {
    title: "3 - Тревога проникновения",
    icon: "mdi-fire",
    message: "Нарушение охранного ШС, взятого на охрану",
  },
  4: {
    title: "4 - Помеха",
    icon: "mdi-fire",
    message:
      "Повышение сигнала в измерительном канале датчика, но меньше уровня тревоги",
  },
  6: { title: "6 - Помеха устранена", icon: "mdi-fire", message: "" },

  9: {
    title: "9 - Активация УДП",
    icon: "mdi-fire",
    message:
      "Нажата кнопка (переключатель и т.п.) адресного или контролируемого с помощью ШС устройства, предназначенного для дистанционного запуска противопожарного оборудования.",
  },
  10: {
    title: "10 - Восстановление УДП",
    icon: "mdi-fire",
    message: "Устройство дистанционного пуска переведено в исходное состояние",
  },
  17: {
    title: "17 - Неудачное взятие",
    icon: "mdi-fire",
    message: "В момент постановки под охрану ШС был нарушен или неисправен",
  },
  18: {
    title: "18 - Предъявлен код принуждения",
    icon: "mdi-fire",
    message: "Предъявлен код принуждения",
  },
  19: {
    title: "19 - Тест пожарного дымового извещателя",
    icon: "mdi-fire",
    message:
      "Срабатывание пожарного дымового извещателя «ДИП-34А» при специальном тестовом воздействии (поднесении магнита или нажатии тестовой кнопки) не в режиме тестирования",
  },
  20: {
    title: "20 - Вход в режим тестирования",
    icon: "mdi-fire",
    message: "ШС переведен в режим «Тестирование»",
  },
  21: {
    title: "21 - Выход из режима тестирования",
    icon: "mdi-fire",
    message: "ШС вышел из режима «Тестирование»",
  },
  22: {
    title: "22 - Восстановление контроля",
    icon: "mdi-fire",
    message: "Восстановление контроля программируемого технологического входа",
  },
  23: {
    title: "23 - Задержка взятия",
    icon: "mdi-fire",
    message: "Включилась задержка на выход (задержка взятия на охрану)",
  },
  24: {
    title: "24 - Взятие входа на охрану",
    icon: "mdi-fire",
    message: "Вход взят на охрану",
  },
  34: {
    title: "34 - Идентификация",
    icon: "mdi-fire",
    message:
      "Пользователь ввёл код для управления (например, для постановки на охрану или снятия с охраны)",
  },
  35: {
    title: "35 - Восстановление технологического входа",
    icon: "mdi-fire",
    message: "",
  },
  36: {
    title: "36 - Нарушение технологического входа",
    icon: "mdi-fire",
    message: "",
  },
  37: {
    title: "37 - Пожар",
    icon: "mdi-fire",
    message:
      "Обычно это срабатывание двух пороговых извещателей в шлейфе сигнализации, либо истекла задержка перехода в «Пожар» после срабатывания порогового извещателя, либо превышение измеряемой величиной (температура или задымленность) порога «Пожар» в адресноаналоговой зоне",
  },
  38: {
    title: "38 - Нарушение 2-го технологического входа",
    icon: "mdi-fire",
    message: "Другое нарушение технологического входа",
  },
  39: {
    title: "39 - Восстановление нормы оборудования",
    icon: "mdi-fire",
    message: "",
  },
  40: {
    title: "40 - Пожар 2",
    icon: "mdi-fire",
    message:
      "Состояние «Пожар» не менее двух ШС или автоматических адресных извещателей, принадлежащих одной контролируемой области (одному разделу), либо «Пожар» в зоне, контролирующей ручной извещатель",
  },
  41: {
    title: "41 - Неисправность оборудования",
    icon: "mdi-fire",
    message:
      "Неисправность оборудования. Это либо внутренняя неисправность адресного извещателя (неисправность оптической системы «ДИП-34А»), либо нарушение цепей контроля массы и давления прибора «С2000КПБ»",
  },
  42: { title: "42 - Неизвестное устройство", icon: "mdi-fire", message: "" },
  44: {
    title: "44 - «Внимание!»",
    icon: "mdi-fire",
    message:
      "Обычно это срабатывание порогового теплового пожарного извещателя, подтвержденное срабатывание порогового дымового извещателя, превышение измеряемой величиной (температура или задымленность) порога «Внимание» в адресноаналоговой зоне",
  },
  45: {
    title: "45 - Обрыв входа",
    icon: "mdi-fire",
    message:
      "Обрыв шлейфа сигнализации или контролируемой цепи адресного расширителя",
  },
  46: {
    title: "46 - Обрыв ДПЛС",
    icon: "mdi-fire",
    message: "Обрыв двухпроводной линии связи",
  },
  47: {
    title: "47 - Восстановление ДПЛС",
    icon: "mdi-fire",
    message: "Восстановление двухпроводной линии после обрыва или КЗ",
  },
  58: {
    title: "58 - Тихая тревога",
    icon: "mdi-fire",
    message: "Нарушение тревожного ШС",
  },
  71: {
    title: "71 - Понижение уровня",
    icon: "mdi-fire",
    message: "Понижение уровня воды или давления («Поток-3Н»)",
  },
  72: {
    title: "72 - Норма уровня",
    icon: "mdi-fire",
    message: "Восстановление уровня воды или давления («Поток-3Н»)",
  },
  74: {
    title: "74 - Повышение уровня",
    icon: "mdi-fire",
    message: "Повышение уровня воды или давления («Поток-3Н»)",
  },
  75: {
    title: "75 - Аварийное повышение уровня",
    icon: "mdi-fire",
    message: "Превышение аварийного уровня воды или давления («Поток-3Н»)",
  },
  76: {
    title: "76 - Повышение температуры",
    icon: "mdi-fire",
    message: "Температура превысила максимально допустимое значение",
  },
  77: {
    title: "77 - Аварийное понижение уровня",
    icon: "mdi-fire",
    message:
      "Понижение уровня воды или давления ниже аварийного значения («Поток-3Н»)",
  },
  78: {
    title: "78 - Температура в норме",
    icon: "mdi-fire",
    message:
      "Температура в установленных границах («температурная» зона «С2000-КДЛ»)",
  },
  79: {
    title: "79 - Тревога затопления",
    icon: "mdi-fire",
    message: "Срабатывание датчика затопления (протечки)",
  },
  80: {
    title: "80 - Восстановление датчика затопления",
    icon: "mdi-fire",
    message: "Восстановление датчика затопления (протечки).",
  },
  82: {
    title: "82 - Неисправность термометра",
    icon: "mdi-fire",
    message:
      "Неисправность измерителя температуры («температурная» зона «С2000-КДЛ»)",
  },
  83: {
    title: "83 - Восстановление термометра",
    icon: "mdi-fire",
    message:
      "Восстановление измерителя температуры («температурная» зона «С2000-КДЛ»)",
  },
  84: {
    title: "84 - Начало локального программирования",
    icon: "mdi-fire",
    message: "",
  },
  90: {
    title: "90 - Неисправность канала связи",
    icon: "mdi-fire",
    message: "Неисправность канала передачи извещений абоненту",
  },
  91: {
    title: "91 - Восстановление канала связи",
    icon: "mdi-fire",
    message: "Восстановление канала передачи извещений абоненту",
  },
  109: {
    title: "109 - Снятие входа с охраны",
    icon: "mdi-fire",
    message: "Вход снят с охраны",
  },
  110: {
    title: "110 - Сброс тревоги",
    icon: "mdi-fire",
    message: "Сброшено состояние «тревога» или «пожар»",
  },
  117: {
    title: "117 - Восстановление снятого входа",
    icon: "mdi-fire",
    message: "Восстановление нормы снятого охранного входа",
  },
  118: {
    title: "118 - Тревога входа",
    icon: "mdi-fire",
    message: "Тревога входной зоны",
  },
  119: {
    title: "119 - Нарушение снятого входа",
    icon: "mdi-fire",
    message: "Нарушение снятого охранного входа",
  },
  121: {
    title: "121 - Обрыв выхода",
    icon: "mdi-fire",
    message: "Обрыв цепи нагрузки релейного выхода",
  },
  122: {
    title: "122 - КЗ выхода",
    icon: "mdi-fire",
    message: "Короткое замыкание цепи нагрузки релейного выхода",
  },
  123: {
    title: "123 - Восстановление выхода",
    icon: "mdi-alert-circle-check-outline",
    message:
      "Восстановление релейного выхода (восстановление после неисправности цепи нагрузки выхода)",
  },
  126: {
    title: "126 - Потеря связи с выходом",
    icon: "mdi-lan-disconnect",
    message:
      "Управление выходом (реле) недоступно из-за отсутствия связи с ним: потеряна связь контроллера «С2000-КДЛ» с адресным релейным модулем «С2000-СП2», либо потеряна связь «С2000-АСПТ» с подключенными к нему «С2000-КПБ»",
  },
  127: {
    title: "127 - Восстановление связи с выходом",
    icon: "mdi-fire",
    message:
      "Восстановлено управление выходом (реле): восстановлена связь контроллера «С2000-КДЛ» с потерянным ранее адресным релейным блоком «С2000-СП2», либо восстановлена связь «С2000-АСПТ» с «С2000-КПБ»",
  },
  128: {
    title: "128 - Изменение состояния выхода",
    icon: "mdi-fire",
    message:
      "Изменение состояния исполнительного выхода: включение, включение в прерывистом режиме, выключение",
  },
  130: { title: "130 - Включение насоса", icon: "mdi-fire", message: "" },
  131: { title: "131 - Выключение насоса", icon: "mdi-fire", message: "" },
  135: {
    title: "135 - Ошибка при автоматическом тестировании",
    icon: "mdi-fire",
    message: "Выявлен сбой (неисправность) в оборудовании",
  },
  137: {
    title: "137 - Пуск",
    icon: "mdi-fire",
    message: "Срабатывание цепи пуска",
  },
  139: {
    title: "139 - Неудачный пуск пожаротушения",
    icon: "mdi-fire",
    message:
      "Неудачный запуск автоматической установки пожаротушения (пусковой импульс был выдан, но не зафиксирован выход огнетушащего вещества)",
  },
  140: {
    title: "140 - Тест",
    icon: "mdi-fire",
    message: "Запуск внутреннего теста",
  },
  141: {
    title: "141 - Задержка пуска АУП",
    icon: "mdi-fire",
    message:
      "Выполнилось условие пуска аппаратуры управления пожаротушением и идет отсчет задержки перед выдачей пускового импульса",
  },
  142: {
    title: "142 - Автоматика АУП выключена",
    icon: "mdi-fire",
    message: "Режим автоматического запуска АУП выключен",
  },
  143: {
    title: "143 - Отмена пуска АУП",
    icon: "mdi-fire",
    message:
      "Пуск АУП был отменен (например, во время задержки запуска была нажата кнопка «СБРОС» прибора «С2000-АСПТ», либо с пульта дана команда «ОТМЕНИТЬ ПУСК»)",
  },
  144: {
    title: "144 - Тушение",
    icon: "mdi-fire",
    message:
      "Идет тушение (после выдачи пускового импульса зафиксирован выход огнетушащего вещества)",
  },
  145: {
    title: "145 - Аварийный пуск АУП",
    icon: "mdi-fire",
    message:
      "Аварийный пуск аппаратуры пожаротушения (пускового импульса не было, но зафиксирован выход огнетушащего вещества)",
  },
  146: {
    title: "146 - Пуск АУП",
    icon: "mdi-fire",
    message: "Выдан импульс пуска аварийной установки пожаротушения",
  },
  147: {
    title: "147 - Блокировка пуска АУП",
    icon: "mdi-fire",
    message:
      "Пуск АУП был заблокирован (например, во время задержки запуска была открыта дверь в защищаемое помещение)",
  },
  148: {
    title: "148 - Автоматика АУП включена",
    icon: "mdi-fire",
    message: "Режим автоматического пуска АУП включен",
  },
  149: {
    title: "149 - Взлом корпуса прибора",
    icon: "mdi-screwdriver",
    message: "",
  },
  150: {
    title: "150 - Пуск речевого оповещения",
    icon: "mdi-fire",
    message: "Выполнен запуск речевого оповещения (РО)",
  },
  151: {
    title: "151 - Отмена пуска РО",
    icon: "mdi-fire",
    message: "Отмена пуска речевого оповещения",
  },
  152: {
    title: "152 - Восстановление корпуса прибора",
    icon: "mdi-fire",
    message: "",
  },
  153: {
    title: "153 - ИУ в рабочем состоянии",
    icon: "mdi-fire",
    message:
      "Клапан приточно-вытяжной вентиляции или дымоудаления перешел в рабочее состояние",
  },
  154: {
    title: "154 - ИУ в исходном состоянии",
    icon: "mdi-fire",
    message:
      "Клапан приточно-вытяжной вентиляции или дымоудаления перешел в исходное состояние",
  },
  155: {
    title: "155 - Отказ ИУ",
    icon: "mdi-fire",
    message: "Клапан не перешел в рабочее или исходное состояние",
  },
  156: {
    title: "156 - Ошибка ИУ",
    icon: "mdi-fire",
    message: "Некорректное состояние цепей контроля клапана",
  },
  158: {
    title: "158 - Восстановление внутренней зоны",
    icon: "mdi-fire",
    message: "",
  },
  159: {
    title: "159 - Задержка пуска РО",
    icon: "mdi-fire",
    message: "Идёт задержка перед пуском РО",
  },
  161: {
    title: "161 - Останов задержки пуска АУП",
    icon: "mdi-fire",
    message: "Отсчёт задержки пуска пожаротушения остановлен",
  },
  165: {
    title: "165 - Ошибка параметров входа",
    icon: "mdi-fire",
    message: "Вход неработоспособен из-за ошибок параметров конфигурации",
  },
  187: {
    title: "187 - Потеря связи со входом",
    icon: "mdi-fire",
    message:
      "Отключен извещатель: потеряна связь контроллера «С2000-КДЛ» с адресным извещателем или расширителем, либо потеряна связь «С2000-АСПТ» с подключенными к нему «С2000-КПБ»",
  },
  188: {
    title: "188 - Восстановление связи со входом",
    icon: "mdi-fire",
    message:
      "Подключен извещатель: восстановлена связь «С2000-КДЛ» с потерянным ранее адресным извещателем или расширителем, либо восстановлена связь «С2000АСПТ» с «С2000-КПБ»",
  },
  189: {
    title: "189 - Потеря связи по ДПЛС1",
    icon: "mdi-fire",
    message: "Потеряна связь с извещателем по ветви 1 кольцевой ДПЛС",
  },
  190: {
    title: "190 - Потеря связи по ДПЛС2",
    icon: "mdi-fire",
    message: "Потеряна связь с извещателем по ветви 2 кольцевой ДПЛС",
  },
  191: {
    title: "191 - Восстановление связи по ДПЛС1",
    icon: "mdi-fire",
    message:
      "Восстановлена связь с одним или несколькими адресными извещателями по ветви 1 кольцевой ДПЛС",
  },
  192: {
    title: "192 - Отключение выходного напряжения",
    icon: "mdi-fire",
    message:
      "Выходное напряжение РИП отключено (выполнена команда отключения выходного напряжения)",
  },
  193: {
    title: "193 - Подключение выходного напряжения",
    icon: "mdi-fire",
    message:
      "Выходное напряжение РИП включено (выполнена команда включения выходного напряжения)",
  },
  194: {
    title: "194 - Перегрузка источника питания",
    icon: "mdi-fire",
    message: "Перегрузка резервированного источника питания (РИП)",
  },
  195: {
    title: "195 - Перегрузка источника питания устранена",
    icon: "mdi-fire",
    message: "Перегрузка резервированного источника питания (РИП) устранена",
  },
  196: {
    title: "196 - Неисправность зарядного устройства",
    icon: "mdi-fire",
    message: "Неисправность зарядного устройства РИП",
  },
  197: {
    title: "197 - Восстановление зарядного устройства",
    icon: "mdi-fire",
    message: "Неисправность зарядного устройства РИП устранена",
  },
  198: {
    title: "198 - Неисправность источника питания",
    icon: "mdi-fire",
    message: "Напряжение питания прибора вышло за допустимые границы",
  },
  199: {
    title: "199 - Восстановление источника питания",
    icon: "mdi-power-plug",
    message: "Напряжение питания прибора пришло в норму после аварии",
  },
  200: {
    title: "200 - Восстановление батареи",
    icon: "mdi-fire",
    message: "Напряжение системной батареи пришло в норму",
  },
  201: {
    title: "201 - Восстановление связи по ДПЛС2",
    icon: "mdi-fire",
    message:
      "Восстановлена связь с одним или несколькими адресными извещателями по ветви 2 кольцевой ДПЛС",
  },
  202: {
    title: "202 - Неисправность батареи",
    icon: "mdi-fire",
    message: "Батареи нет, либо обобщённая неисправность батареи",
  },
  203: {
    title: "203 - Перезапуск прибора",
    icon: "mdi-fire",
    message: "Перезапуск прибора",
  },
  204: {
    title: "204 - Требуется обслуживание",
    icon: "mdi-fire",
    message:
      "Требуется обслуживание извещателя (например, запылена дымовая камера извещателя «ДИП-34А»)",
  },
  205: {
    title: "205 - Ошибка теста АКБ",
    icon: "mdi-fire",
    message:
      "АКБ не прошла тест и признана непригодной для дальнейшей эксплуатации",
  },
  206: {
    title: "206 - Понижение температуры",
    icon: "mdi-fire",
    message:
      "Температура ниже минимально допустимого значения («температурная» зона «С2000-КДЛ»)",
  },
  211: {
    title: "211 - Батарея разряжена",
    icon: "mdi-fire",
    message: "Предупреждение о скором разряде батареи",
  },
  212: {
    title: "212 - Разряд резервной батареи",
    icon: "mdi-fire",
    message:
      "Предупреждение о скором разряде резервной батареи (в пожарных радиоканальных извещателях)",
  },
  213: {
    title: "213 - Восстановление резервной батареи",
    icon: "mdi-fire",
    message:
      "Резервная батарея в норме (в пожарных радиоканальных извещателях)",
  },
  214: {
    title: "214 - Короткое замыкание входа",
    icon: "mdi-fire",
    message:
      "Короткое замыкание шлейфа сигнализации или контролируемой цепи адресного расширителя",
  },
  215: {
    title: "215 - Короткое замыкание ДПЛС",
    icon: "mdi-fire",
    message: "Короткое замыкание двухпроводной линии связи",
  },
  216: {
    title: "216 - Сработка датчика",
    icon: "mdi-fire",
    message: "Неподтверждённое срабатывание пожарного извещателя",
  },
  217: {
    title: "217 - Отключение ветви RS-485",
    icon: "mdi-fire",
    message: "Отключение прибора от одной ветви кольцевого интерфейса RS-485",
  },
  218: {
    title: "218 - Восстановление ветви RS-485",
    icon: "mdi-fire",
    message:
      "Восстановление связи с прибором по ветви кольцевого интерфейса RS-485",
  },
  220: {
    title: "220 - Срабатывание СДУ",
    icon: "mdi-fire",
    message: "Срабатывание датчика выхода огнетушащего вещества",
  },
  221: {
    title: "221 - Отказ СДУ",
    icon: "mdi-fire",
    message: "Несрабатывание датчика выхода огнетушащего вещества",
  },
  222: {
    title: "222 - Повышение напряжения ДПЛС",
    icon: "mdi-fire",
    message:
      "Авария двухпроводной линии связи прибора «С2000-КДЛ» (обычно аномально повышенное напряжение)",
  },
  223: {
    title: "223 - Отметка наряда",
    icon: "mdi-fire",
    message: "Срабатывание цепи контроля наряда",
  },
  237: {
    title: "237 - Раздел снят по принуждению",
    icon: "mdi-fire",
    message: "Раздел снят по принуждению",
  },
  241: {
    title: "241 - Раздел взят",
    icon: "mdi-fire",
    message: "Раздел взят на охрану",
  },
  242: {
    title: "242 - Раздел снят",
    icon: "mdi-fire",
    message: "Раздел снят с охраны",
  },
  250: {
    title: "250 - Потеряна связь с прибором",
    icon: "mdi-fire",
    message: "",
  },
  251: {
    title: "251 - Восстановлена связь с прибором",
    icon: "mdi-fire",
    message: "",
  },
  253: {
    title: "253 - Включение пульта С2000М",
    icon: "mdi-fire",
    message: "",
  },
} as { [key: number]: { title: string; icon: string; message: string } };
