export const RUSSIAN_TRANSLATIONS = {
  button: {
    ok: "ОК",
    yes: "Да",
    no: "Нет",
    delete: "Удалить",
    save: "Сохранить",
    create: "Создать",
    cancel: "Отмена",
    add: "Добавить",
    journal: "Журнал",
    clear: "Очистить",
  },
  emptyHistory: "Нет истории изменения статусов",
  wrongStatus: "Неверный статус",
  wrongStatusMessge: "В коде статуса ошибка",
  form: {
    name: "Название",
    choose: "Выбрать",
    activationStatus: "Уведомлять о статусе",
  },
  calendarButtons: {
    today: "Сегодня",
    yesterday: "Вчера",
    week: "Неделя",
    month: "Месяц",
  },
  message: {
    deleteTitle: "Удалить",
    excludeTitile: "Исключить",
    createPermission: "Создать разрешение",
    editPermission: "Изменить разрешение",
    deleteEntity: "Удалить без возможности восстановления?",
    deleteUserFromGroup: "Исключить пользователя из группы?",
    deletePermission: "Удалить разрешение?",
  },
  information: {
    header: "Информация",
    deviceName: "Наименование устройства",
    address: "Адрес шины",
    status: "Последний статус",
    statusDescription: "Описание статуса",
    fullName: "Полное имя",
  },
  search: "Поиск",
  select: "Выбрать",
  nothingFound: "Ничего не найдено",
  dialog: {
    deleteEntity: "Удалить объект",
    createEntity: "Создать объект",
    editEntity: "Редактировать объект",
    coordinatesMap: "Укажите координаты",
    markerTooltip: "Удерживайте, чтобы переместить",
  },
  coordinatesCopied: "Координаты скопированы",
};
