







































import Vue from "vue";
import { dateTimeFormats } from "../../translations/dateTimeLocale";
const DAY = 24 * 60 * 60 * 1000;
const WEEK = DAY * 7;
export default Vue.extend({
  name: "CalendarFilter",
  props: {
    dates: Array,
    isEmpty: Boolean,
  },
  data() {
    return {
      selfDates: this.dates,
      dateLocale: dateTimeFormats[this.$i18n.locale],
      toggled: {},
    };
  },
  watch: {
    dates(val) {
      this.selfDates = val;
    },
    selfDates(val) {
      this.$emit("update:dates", val);
    },
  },
  computed: {
    datesButtons(): any[] {
      return [
        {
          label: this.$t("calendarButtons.yesterday"),
          onClick: this.yesterday,
        },
        {
          label: this.$t("calendarButtons.today"),
          onClick: this.today,
        },
        {
          label: this.$t("calendarButtons.week"),
          onClick: this.lastWeek,
        },
        {
          label: this.$t("calendarButtons.month"),
          onClick: this.lastMonth,
        },
      ];
    },
  },
  methods: {
    today() {
      this.selfDates = [new Date(), new Date()];
    },
    yesterday() {
      const today = new Date();
      const yesterday = new Date(today.getTime() - DAY);
      this.selfDates = [yesterday, yesterday];
    },
    lastWeek() {
      const today = new Date();
      this.selfDates = [new Date(today.getTime() - WEEK), today];
    },
    lastMonth() {
      const today = new Date();
      const milisecondsInCurrentMonth =
        new Date(today.setMonth(today.getMonth(), 0)).getDate() * DAY;
      this.selfDates = [
        new Date(new Date().getTime() - milisecondsInCurrentMonth),
        new Date(),
      ];
    },
    clearDate() {
      this.selfDates = [new Date(), null];
    },
  },
});
