












































































import { uuid } from "@/utils";
import Mixins from "./common/Mixins";

export default Mixins.extend({
  name: "BolidSubscriptionDialog",
  props: {
    currentSubscription: Object,
    visible: Boolean,
  },
  data() {
    return {
      show: this.visible,
      localSubscription: {} as BolidSubscription,
    };
  },
  watch: {
    visible(val) {
      this.show = val;
      this.localSubscription = {
        id: "",
        name: "",
        ...this.currentSubscription,
      };
    },
  },
  methods: {
    onClose() {
      this.$emit("update:visible", false);
    },
    createUpdateSubscription() {
      if (this.localSubscription.id === "") {
        this.localSubscription.id = uuid();
      } else {
        this.deleteSubscription();
      }
      const storedSubscriptions = JSON.parse(
        localStorage.getItem("bolidSubscriptions") || "[]"
      );
      localStorage.setItem(
        "bolidSubscriptions",
        JSON.stringify([this.localSubscription, ...storedSubscriptions])
      );
      this.show = false;
    },
    deleteSubscription() {
      const storedSubscriptions = JSON.parse(
        localStorage.getItem("bolidSubscriptions") || "[]"
      ) as BolidSubscription[];
      localStorage.setItem(
        "bolidSubscriptions",
        JSON.stringify(
          storedSubscriptions.filter(
            ({ id }) => this.localSubscription.id !== id
          )
        )
      );
    },
  },
  computed: {
    isDisabled(): Boolean {
      return (
        [undefined, null, ""].includes(this.localSubscription.name) ||
        this.localSubscription.activationStatus === undefined
      );
    },
  },
});
