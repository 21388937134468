






import Vue from "vue";
import BolidDeviceCard from "./components/BolidDeviceCard.vue";
import { bolidStatuses } from "@/bolidStatuses";

export default Vue.extend({
  name: "App",
  components: {
    BolidDeviceCard,
  },
  data() {
    const props = this.$parent?.props;
    return {
      options: {
        bolidDevice: props?.bolidDevice as BolidDevice,
        isCompactView: props?.options.isCompactView,
        bolidStatuses: bolidStatuses || {},
        compactViewSubscriptor: props?.options
          .compactViewSubscriptor as Function,
        isMapView: props?.options.isMapView || (false as Boolean),
        toast: props?.options.toast,
      } as BolidDeviceOptions,
    };
  },
  beforeMount() {
    this.$tl = this.$t;
  },
});
