(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("primevue/calendar"), require("primevue/scrollpanel"), require("primevue/tooltip"), require("single-spa-vue"), require("vue-router"), require("primevue/toastservice"), require("vue"), require("@netvision/lib-api-gateway"), require("primevue/multiselect"), require("primevue/toast"), require("primevue/dialog"), require("primevue/button"));
	else if(typeof define === 'function' && define.amd)
		define(["primevue/calendar", "primevue/scrollpanel", "primevue/tooltip", "single-spa-vue", "vue-router", "primevue/toastservice", "vue", "@netvision/lib-api-gateway", "primevue/multiselect", "primevue/toast", "primevue/dialog", "primevue/button"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("primevue/calendar"), require("primevue/scrollpanel"), require("primevue/tooltip"), require("single-spa-vue"), require("vue-router"), require("primevue/toastservice"), require("vue"), require("@netvision/lib-api-gateway"), require("primevue/multiselect"), require("primevue/toast"), require("primevue/dialog"), require("primevue/button")) : factory(root["primevue/calendar"], root["primevue/scrollpanel"], root["primevue/tooltip"], root["single-spa-vue"], root["vue-router"], root["primevue/toastservice"], root["vue"], root["@netvision/lib-api-gateway"], root["primevue/multiselect"], root["primevue/toast"], root["primevue/dialog"], root["primevue/button"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__0482__, __WEBPACK_EXTERNAL_MODULE__342a__, __WEBPACK_EXTERNAL_MODULE__3b23__, __WEBPACK_EXTERNAL_MODULE__4171__, __WEBPACK_EXTERNAL_MODULE__6389__, __WEBPACK_EXTERNAL_MODULE__68f9__, __WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE_bb6b__, __WEBPACK_EXTERNAL_MODULE_be9f__, __WEBPACK_EXTERNAL_MODULE_d26e__, __WEBPACK_EXTERNAL_MODULE_dec9__, __WEBPACK_EXTERNAL_MODULE_f095__) {
return 