var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bolid-device-container p-d-flex p-flex-column ",style:({
    height: _vm.isCompactView ? 'initial' : 'inherit',
    background: _vm.isMapView ? 'initial' : 'var(--bg-gradient-dark)',
  })},[_c('div',{staticClass:"bolid-device__header p-d-flex p-ai-center p-jc-center p-raised"},[_c('i',{staticClass:"mdi",class:_vm.bolidDevice.icon.iconClass,staticStyle:{"font-size":"calc(120rem / var(--bfs))"}})]),_c('div',{staticClass:"bolid-device_info-container p-raised",class:{ 'map-background': _vm.isMapView }},[_c('div',{staticClass:"p-d-flex p-flex-nowrap p-mx-2",staticStyle:{"cursor":"pointer"},style:({
        cursor: _vm.isMapView ? 'auto' : 'pointer',
      }),on:{"click":function($event){!_vm.isMapView ? _vm.collapseExpand() : function () {}}}},[_c('i',{staticClass:"p-mt-2 p-mr-2 mdi mdi-24px",class:_vm.bolidDevice.icon.iconClass}),_c('div',{staticClass:"p-d-flex p-flex-column p-mt-1 p-mb-1",staticStyle:{"width":"100%","overflow":"hidden"}},[_c('span',{staticClass:"bolid-device__title p-mb-1 text-ellipsis",domProps:{"textContent":_vm._s(_vm.bolidDevice.title)}}),_c('span',{staticClass:"bolid-device__subtitle text-ellipsis",domProps:{"textContent":_vm._s(
            _vm.bolidStatuses[_vm.bolidDevice.status]
              ? _vm.bolidStatuses[_vm.bolidDevice.status].title
              : ''
          )}}),(!_vm.bolidStatuses[_vm.bolidDevice.status] && !_vm.isMapView)?_c('br'):_vm._e()]),_c('i',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMapView),expression:"!isMapView"}],staticClass:"p-mt-2 p-ml-2 mdi mdi-24px mdi-menu-right",style:({
          color: 'var(--text-color-secondary)',
          transform: _vm.isCompactView ? 'rotate(0deg)' : 'rotate(90deg)',
          transition: 'all 0.3s',
        })})]),_c('div',{staticClass:"p-d-flex p-mx-2 p-mb-1 p-flex-column p-jc-between",style:({
        borderBottom:
          !_vm.isCompactView && !_vm.isMapView
            ? '1px solid rgba(var(--secondary-color-raw), 0.65)'
            : '1px solid transparent',
      })},[_c('div',{staticClass:"p-d-flex",staticStyle:{"margin-left":"-1rem","width":"calc(100% + 2rem)"}},[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(_vm.$t('button.add')),expression:"$t('button.add')",modifiers:{"right":true}}],staticClass:"p-button-rounded p-button-plain p-button-text",staticStyle:{"flex-shrink":"0","color":"var(--text-color)"},attrs:{"icon":"mdi mdi-24px mdi-plus-circle-outline"},on:{"click":function($event){return _vm.openDialog({
              deviceId: _vm.bolidDevice.id,
              deviceName: _vm.bolidDevice.title,
            })}}}),_c('div',{staticClass:"bolid-device__assignments-list",attrs:{"id":("scrollPanel-" + (_vm.bolidDevice.id))}},[_c('ScrollPanel',_vm._l((_vm.bolidSubscriptions),function(bolidSubscription){return _c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.unbreakable(bolidSubscription.name)),expression:"unbreakable(bolidSubscription.name)",modifiers:{"top":true}}],key:bolidSubscription.id,staticClass:"p-button-rounded p-button-text",staticStyle:{"flex-shrink":"0"},attrs:{"icon":("mdi mdi-24px " + (_vm.bolidStatuses[
                  bolidSubscription.activationStatus
                ] && _vm.bolidStatuses[bolidSubscription.activationStatus].icon))},on:{"click":function($event){return _vm.openDialog(Object.assign({}, bolidSubscription))}}})}),1)],1),_c('div',[_c('Button',{staticClass:"p-button-plain p-button-text",staticStyle:{"font-size":"16px"},attrs:{"label":_vm.$t('button.journal')},on:{"click":function($event){_vm.showBolidJournalDialog = true}}})],1)],1)]),_c('transition',{attrs:{"name":"slide"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCompactView),expression:"!isCompactView"}],staticStyle:{"display":"flex","flex-flow":"column wrap","flex":"1"}},[_c('div',{staticClass:"p-d-flex p-mx-2 p-mt-1 p-mb-1 p-flex-column p-jc-between"},[_c('div',{staticClass:"bolid-device__info"},[_c('div',[_c('span',{domProps:{"textContent":_vm._s(_vm.bolidDevice.location)}})]),_c('input',{ref:("coords-" + (_vm.bolidDevice.id)),staticStyle:{"position":"absolute","top":"calc(-40rem / var(--bfs))","opacity":"0"},attrs:{"type":"text"}}),_c('div',{staticClass:"p-d-flex p-ai-center p-jc-end"},[_c('i',{staticClass:"mdi mdi-content-copy",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.copyToClipboard()}}})])]),_c('div',{staticClass:"bolid-device__info"},[_c('div',[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('information.fullName'))}})]),_c('div',[_c('span',{domProps:{"textContent":_vm._s(_vm.bolidDevice.title)}})])]),(_vm.bolidDevice.status)?_c('div',{staticClass:"bolid-device__info"},[_c('div',[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('information.status'))}})]),_c('div',[_c('span',{domProps:{"textContent":_vm._s(
                  ((_vm.$t('information.statusDescription')) + ":  " + (_vm.bolidStatuses[_vm.bolidDevice.status]
                      ? _vm.bolidStatuses[_vm.bolidDevice.status].message
                      : ''))
                )}})])]):_vm._e()]),_c('div',{staticStyle:{"flex-grow":"1"}})])]),(_vm.isMapView)?_c('div',{staticClass:"onmap-footter"},[_c('div',{staticClass:"onmap-footter-button",on:{"click":function($event){_vm.isCompactView = !_vm.isCompactView}}},[_c('i',{class:_vm.isCompactView
              ? 'mdi mdi-18px mdi-menu-down'
              : 'mdi mdi-18px mdi-menu-up'})])]):_vm._e()],1),_c('v-teleport',{attrs:{"to":"BolidSubscriptionDialog"}},[_c('div',[_c('BolidSubscriptionDialog',{attrs:{"visible":_vm.showBolidSubscriptionDialog,"currentSubscription":_vm.currentBolidSubscription},on:{"update:visible":function($event){_vm.showBolidSubscriptionDialog=$event}}}),_c('BolidJournalDialog',{attrs:{"visible":_vm.showBolidJournalDialog,"bolidDevice":_vm.bolidDevice},on:{"update:visible":function($event){_vm.showBolidJournalDialog=$event}}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }