












































































































import Mixins from "./common/Mixins";
import {
  createCamerasConnection,
  listEntities,
} from "@netvision/lib-api-gateway";
import CalendarFilter from "./common/CalendarFilter.vue";

const formatter = new Intl.DateTimeFormat("ru", {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
});

export default Mixins.extend({
  components: { CalendarFilter },
  name: "BolidJournalDialog",
  props: {
    bolidDevice: Object as () => BolidDevice,
    visible: Boolean,
    t: Function,
  },
  data() {
    return {
      show: this.visible,
      localSubscription: {} as BolidSubscription,
      histories: [] as BolidHistory[],
      statusFilter: [] as number[],
      limit: 20,
      offset: 0,
      count: 0 as number | null,
      infiniteScroll: true,
      loading: true,
      dates: [new Date(), null] as [Date, Date | null],
    };
  },
  watch: {
    visible(val) {
      this.show = val;
      if (val) {
        this.initHistories();
      }
    },
    statusFilter() {
      this.loading = true;
      this.count = null;

      this.requestDelay(true, this.initHistories);
    },
    dates() {
      this.loading = true;
      this.count = null;
      this.requestDelay(true, this.initHistories);
    },
  },
  methods: {
    onScroll({ target }: Event) {
      const tagetHTML = target as HTMLElement;
      if (
        this.infiniteScroll &&
        !this.loading &&
        tagetHTML &&
        tagetHTML.scrollTop + tagetHTML.clientHeight >=
          tagetHTML.scrollHeight - 10
      ) {
        this.fetchHistory();
      }
    },
    initHistories() {
      this.offset = 0;
      this.histories = [];
      this.infiniteScroll = true;
      this.fetchHistory();
    },
    fetchHistory(): Promise<void> {
      if (!this.infiniteScroll) {
        return Promise.resolve();
      }
      let q = `entityId==${this.bolidDevice.id}`;
      q +=
        this.statusFilter.length !== 0
          ? `;entityAttrs.status==${this.statusFilter.join(",")}`
          : ";entityAttrs.status";
      q +=
        this.dates[1] !== null
          ? `;dateCreated==${new Date(
              this.dates[0].setHours(0, 0, 0, 0)
            ).toISOString()}..${new Date(
              this.dates[1].setHours(23, 59, 59, 999)
            ).toISOString()}`
          : "";
      this.loading = true;
      return listEntities<BolidHistory>(createCamerasConnection(), {
        offset: this.offset,
        limit: this.limit,
        type: "History",
        count: true,
        orderBy: "timestamp",
        q,
      })
        .then((response) => {
          const { count, results: histories } = response;
          this.count = count || 0;
          if (histories.length === 0) {
            this.infiniteScroll = false;
            return;
          }
          this.histories = [...this.histories, ...histories];
          if (this.histories.length === this.count) {
            this.infiniteScroll = false;
          }
          this.offset += this.limit;
        })
        .catch(this.errorToast)
        .finally(() => {
          this.loading = false;
        });
    },
    getBolidDeviceStatus(key: number | string) {
      const value = this.bolidStatuses[Number(key)];
      return value
        ? value
        : {
            title: this.$t("wrongStatus"),
            icon: "",
            message: this.$t("wrongStatusMessage"),
          };
    },
    format(date: string | number | null) {
      if (typeof date === "number") {
        return formatter.format(new Date(date * 1000));
      } else {
        const parsedDate = Date.parse(date || "");
        return isNaN(parsedDate) ? "" : formatter.format(parsedDate);
      }
    },
    onClose() {
      this.$emit("update:visible", false);
    },
  },
});
