































































































































































































import Vue from "vue";
import { getSharedNotificationSocket } from "@netvision/lib-api-gateway";
import BolidSubscriptionDialog from "./BolidSubscriptionDialog.vue";
import BolidJournalDialog from "./BolidJournalDialog.vue";

export default Vue.extend({
  name: "BolidDeviceCard",
  components: {
    BolidSubscriptionDialog,
    BolidJournalDialog,
  },
  props: {
    options: Object as () => BolidDeviceOptions,
  },
  data() {
    return {
      ...this.options,
      showBolidSubscriptionDialog: false,
      showBolidJournalDialog: false,
      bolidSubscriptions: [] as any,
      currentBolidSubscription: {},
      removeListener: () => {},
      scrollPanel: {} as any,
      unsubscribeCompactView: () => {},
    };
  },
  watch: {
    showBolidSubscriptionDialog(val) {
      if (!val) {
        this.updateSubscriptions();
      }
    },
  },
  methods: {
    unbreakable(str: string) {
      const maxStrLength = 20;
      let unbreakableStr = str.replace(" ", "\xa0").replace("-", "\u{2011}");
      if (unbreakableStr.length > maxStrLength) {
        unbreakableStr = unbreakableStr.slice(maxStrLength) + "...";
      }
      return unbreakableStr;
    },
    collapseExpand() {
      this.isCompactView = !this.isCompactView;
    },
    scrollHorizontally(e: any) {
      e = window.event || e;
      let delta = Math.max(-1, Math.min(1, e.wheelDelta || -e.detail));
      this.scrollPanel!.scrollLeft -= delta * 40; // Multiplied by 40
      e.preventDefault();
    },
    openDialog(subscription: any) {
      this.currentBolidSubscription = subscription;
      this.showBolidSubscriptionDialog = true;
    },
    copyToClipboard() {
      let inputField = this.$refs[
        `coords-${this.bolidDevice.id}`
      ] as HTMLInputElement;
      inputField = Array.isArray(inputField) ? inputField[0] : inputField;
      inputField.value = this.bolidDevice.location;
      inputField.select();
      document.execCommand("copy");
      this.toast.add({
        severity: "info",
        detail: this.$t("coordinatesCopied"),
        life: 3000,
      });
    },
    updateSubscriptions() {
      const storedSubscriptions = JSON.parse(
        localStorage.getItem("bolidSubscriptions") || "[]"
      ) as BolidDevice[];
      this.bolidSubscriptions = storedSubscriptions.filter(
        ({ deviceId }) => deviceId === this.bolidDevice.id
      );
    },
  },
  mounted() {
    this.updateSubscriptions();
    if (typeof this.compactViewSubscriptor === "function") {
      this.unsubscribeCompactView = this.compactViewSubscriptor(
        this.bolidDevice.id,
        (val: boolean) => {
          this.isCompactView = val;
        }
      );
    }
    this.removeListener = getSharedNotificationSocket().addListener(
      "BolidDevice",
      (entity: BolidDevice) => {
        if (entity.id === this.bolidDevice.id) {
          this.$set(this.bolidDevice, "status", entity.status);
        }
      }
    );
    setTimeout(() => {
      this.scrollPanel = document.querySelector(
        `#scrollPanel-${this.bolidDevice.id} > div > div.p-scrollpanel-wrapper > div.p-scrollpanel-content`
      );
      if (this.scrollPanel !== null) {
        if (this.scrollPanel.addEventListener !== undefined) {
          document;
          this.scrollPanel.addEventListener(
            "mousewheel",
            this.scrollHorizontally,
            false
          );
          document;
          this.scrollPanel.addEventListener(
            "DOMMouseScroll",
            this.scrollHorizontally,
            false
          );
        } else {
          document;
          this.scrollPanel
            // @ts-ignore
            .attachEvent("onmousewheel", this.scrollHorizontally);
        }
      }
    });
  },
  beforeDestroy() {
    this.removeListener();
    this.unsubscribeCompactView();
  },
});
